import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss']
})
export class PendingComponent implements OnInit, OnDestroy {
  item: any;
  id: any;
  isProcessing: boolean = true;
  intervalId: any;
  progressAmount: number = 0;

  constructor(private modalService : BsModalService, private commonService : CommonService, private activateRoute : ActivatedRoute, private router : Router) {}

  @ViewChild('successModal', { static: true }) successModal: any;
  closingModalRef!: BsModalRef;
  

  startProgress(): void {
    // setTimeout(() => {
      this.intervalId = setInterval(() => {
        this.progressAmount += 10;

        // if (this.progressAmount >= 100) {
        //   setTimeout(() => {
        //     clearInterval(this.intervalId);
        //     this.reverseAnimation();
        //   }, 300);
        // }
      }, 300);
    // }, 2000);
  }

  // Reverse the animation state when progress completes
  reverseAnimation(): void {
    this.isProcessing = false;
  }

  // Debug button action to toggle the state
  // toggleProcessing(): void {
  //   this.isProcessing = !this.isProcessing;
  // }
  ngOnInit() {
    this.startProgress()
    
    this.activateRoute.params.subscribe((params) => {
      this.item = params['item'];
    });

    this.id   = this.router.url.split('?')[1]
    const payerId = this.id.split('=')[3];  // Extracts the 'PAYID-...' part

// Extract payerId (the value after 'PayerID=')
let paymentId = this.id.split('=')[1];
paymentId = paymentId.split('&')[0];
    console.log(this.item,this.id)
    this.openModal(this.successModal)
    let obj = {
      "paymentId": paymentId,
      "PayerID": payerId
    }
    this.commonService.paypalExecute(obj).subscribe((data:any) => {
      console.log(data)
      if(data.state == 'approved') {
        clearInterval(this.intervalId);
        this.reverseAnimation();
        setTimeout(() => {
        this.item =='accomodations' ? this.router.navigate(['accomodations/success']) : this.router.navigate(['transfers/success'])
        },2000)
      }
    })
  }

  openModal(modal :any) {
    this.closingModalRef = this.modalService?.show(modal, {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
    });
  }

  ngOnDestroy() {
    this.closingModalRef?.hide()
    clearInterval(this.intervalId);
  }

}
