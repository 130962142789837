import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './../modules/material/material.module';
import { SupportComponent } from './reusableComponents/support/support.component';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './reusableComponents/header/header.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './reusableComponents/footer/footer.component';
import { WhyChooseComponent } from './reusableComponents/whyChoose/whyChoose.component';
import { ChooseUsComponent } from './reusableComponents/chooseUs/chooseUs.component';
import { OurLocationsComponent } from './reusableComponents/ourLocations/ourLocations.component';
import { ContactDetailsComponent } from './reusableComponents/contactDetails/contactDetails.component';
import { SplashScreenComponent } from './reusableComponents/splashScreen/splashScreen.component';
import { LoaderComponent } from './reusableComponents/loader/loader.component';
import { SuccessfulComponent } from './reusableComponents/successful/successful.component';
import { MyAccountComponent } from './reusableComponents/my-account/my-account.component';
import { BookingComponent } from './reusableComponents/booking/booking.component';
import { FailureComponent } from './reusableComponents/failure/failure.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FavouritesComponent } from './reusableComponents/favourites/favourites.component';
import { SearchformModalComponent } from './reusableComponents/favourites/searchform-modal/searchform-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { NgxPrintModule } from 'ngx-print';
import { TransfersListingComponent } from './reusableComponents/transfers-listing/transfers-listing.component';
import { CartComponent } from './cart/cart.component';
import { TransfersSummaryComponent } from './reusableComponents/transfers-summary/transfers-summary.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PendingComponent } from './reusableComponents/pending/pending.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    WhyChooseComponent,
    ChooseUsComponent,
    OurLocationsComponent,
    SupportComponent,
    ContactDetailsComponent,
    SplashScreenComponent,
    LoaderComponent,
    SuccessfulComponent,
    MyAccountComponent,
    BookingComponent,
    FailureComponent,
    FavouritesComponent,
    SearchformModalComponent,
    TransfersListingComponent,
    CartComponent,
    TransfersSummaryComponent,
    PendingComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    HttpClientModule,
    CalendarModule,
    InputTextModule,
    ButtonModule,
    BsDatepickerModule.forRoot(),
    NgxPrintModule,
    ModalModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    WhyChooseComponent,
    ChooseUsComponent,
    OurLocationsComponent,
    SupportComponent,
    ContactDetailsComponent,
    SplashScreenComponent,
    LoaderComponent,
    BookingComponent,
    CartComponent
  ],
  providers: [],
  bootstrap: []
})
export class SharedModule { }
