<!-- <div class="travel-booking-form-container">
  <mat-card class="travel-booking-card">
    <mat-card-content>
        <form>
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Location :</h2>
            <mat-form-field appearance="outline" class="w-100">
              <input type="text" matInput placeholder="Search" [formControl]="searchControl" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-field-container row px-4">
            <div class="col-md-6">
              <h2 class="form-field-heading">Check-in :</h2>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="checkInPicker">
                <mat-datepicker-toggle matSuffix [for]="checkInPicker"></mat-datepicker-toggle>
                <mat-datepicker #checkInPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <h2 class="form-field-heading">Check-out :</h2>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="checkOutPicker" >
                <mat-datepicker-toggle matSuffix [for]="checkOutPicker"></mat-datepicker-toggle>
                <mat-datepicker #checkOutPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Passengers</h2>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="number" placeholder="Enter number of passengers">
            </mat-form-field>
          </div>
          <div class="form-field-container px-4">
            <h2 class="form-field-heading">Board Base</h2>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select  placeholder="Any">
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="px-4">
            <button class="main-button p-2 mt-2 w-100 mb-3">Search</button>
          </div>
        </form>
      </mat-card-content>
  </mat-card>
</div> -->

<!-- <div class="wrapper">
  <div class="container comingSoon">
    <h1>Coming soonn<span class="dot orange-color">.</span></h1>
  </div>
</div> -->

<div class="wrapper">
  <div class="container">
    <form class="search-form">
      <!-- <div class="form-group position-relative">
        <label for="pickup-location">Pick-up Location:</label>
        <input type="text" id="pickup-location" name="pickup-location" placeholder="Enter pick-up location" 
               (input)="onSearchTransfer($event)" [(ngModel)]="transfer" (focus)="showDropdown = true" (blur)="hideDropdown()">
      
        <ul *ngIf="showDropdown && locations.length > 0" class="dropdown-list">
          <li *ngFor="let location of locations let i=index" (click)="selectLocation(location, i)">
            <i *ngIf="location.type === 'hotel'" class="hotel-icon"></i>
            <i *ngIf="location.type === 'terminal'" class="terminal-icon"></i>
            {{ location.name }}
          </li>
        </ul>
      </div> -->
      
      <div class="form-group position-relative">
        <label for="pickup-location">Pick-up Location:</label>
        <input autocomplete="off" type="text" id="pickup-location" name="pickup-location" placeholder="Enter pick-up location" 
               (input)="onSearchTransfer($event)" [(ngModel)]="transfer" 
               (focus)="showDropdown = true" >
        
        <div class="dropdown-content" *ngIf="showDropdown && locations.length > 0">
          <div class="column">
            <h3>Hotels</h3>
            <ul class>
              <li [matTooltip]="hotel.name" matTooltipPosition="right" class="ellipsis" *ngFor="let hotel of hotelLocations; let i = index" (click)="selectLocation(hotel, i)">
                <i class="hotel-icon"></i>
                {{ hotel.name }}
              </li>
            </ul>
          </div>

          <div class="column">
            <h3>Terminals</h3>
            <ul>
              <li [matTooltip]="terminal.name" matTooltipPosition="right" class="ellipsis" *ngFor="let terminal of terminalLocations; let i = index" (click)="selectLocation(terminal, i)">
                <i class="terminal-icon"></i>
                {{ terminal.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="form-group position-relative">
        <label for="dropoff-location">Drop-off Location:</label>
        <input autocomplete="off" type="text" id="drop-off-location" name="drop-off-location" placeholder="Enter drop-off location" 
               (input)="onSearchDropOff($event)" [(ngModel)]="dropOffTransfer" 
               (focus)="showDropdown2 = true">
        
        <div class="dropdown-content" *ngIf="showDropdown2 && dropOffLocations.length > 0">
          <div class="column">
            <h3>Hotels</h3>
            <ul>
              <li *ngFor="let hotel of dropOffHotelLocations; let i = index" (click)="selectLocation2(hotel, i)">
                <i class="hotel-icon"></i>
                {{ hotel.name }}
              </li>
            </ul>
          </div>
          
          <div class="column">
            <h3>Terminals</h3>
            <ul>
              <li *ngFor="let terminal of dropOffTerminalLocations; let i = index" (click)="selectLocation2(terminal, i)">
                <i class="terminal-icon"></i>
                {{ terminal.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      

      <!-- <div class="form-group position-relative">
        <label for="dropoff-location">Drop-off Location:</label>
        <input type="text" id="drop-off-location" name="drop-off-location" placeholder="Enter drop-off location" 
               (input)="onSearchDropOff($event)" [(ngModel)]="dropOffTransfer" (focus)="showDropdown2 = true" (blur)="hideDropdown2()">
      
        <ul *ngIf="showDropdown2 && dropOffLocations.length > 0" class="dropdown-list">
          <li *ngFor="let dropOffLocation of dropOffLocations let i=index" (click)="selectLocation2(dropOffLocation, i)">
            <i *ngIf="dropOffLocation.type === 'hotel'" class="hotel-icon"></i>
            <i *ngIf="dropOffLocation.type === 'terminal'" class="terminal-icon"></i>
            {{ dropOffLocation.name }}
          </li>
        </ul>
      </div> -->
      
      <div class="form-group">
        <label for="date">Date:</label>
        <input matInput #drp="bsDatepicker" bsDatepicker [(ngModel)]="bsRangeValue"
        placeholder="Check in - Check out"
        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMM', showWeekNumbers: false ,displayMonths: 2}"
        (bsValueChange)="onPickUpTransfer($event)" [minDate]="minDate">
      </div>
      
      <!-- <div class="form-group">
        <label for="date">Date:</label>
        <input matInput #drp="bsDatepicker" bsDatepicker [(ngModel)]="bsRangeValue" formControlName="date"
        placeholder="Check in - Check out"
        [bsConfig]="{ isAnimated: true, rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', minDate: initialDate, maxDate: maxYearDate, showWeekNumbers: false, preventChangeToNextMonth: true}"
        (bsValueChange)="onDateRangeChange($event)">
      </div>
       -->
      <div class="form-group">
        <label for="time">Time:</label>
        <select id="time" name="time" [(ngModel)]="pickUpTime" (ngModelChange)="onPickUpTimeChange($event)">
          <!-- Options for time -->
          <option value="00:00">00:00</option>
          <option value="00:30">00:30</option>
          <option value="01:00">01:00</option>
          <option value="01:30">01:30</option>
          <option value="02:00">02:00</option>
          <option value="02:30">02:30</option>
          <option value="03:00">03:00</option>
          <option value="03:30">03:30</option>
          <option value="04:00">04:00</option>
          <option value="04:30">04:30</option>
          <option value="05:00">05:00</option>
          <option value="05:30">05:30</option>
          <option value="06:00">06:00</option>
          <option value="06:30">06:30</option>
          <option value="07:00">07:00</option>
          <option value="07:30">07:30</option>
          <option value="08:00">08:00</option>
          <option value="08:30">08:30</option>
          <option value="09:00">09:00</option>
          <option value="09:30">09:30</option>
          <option value="10:00">10:00</option>
          <option value="10:30">10:30</option>
          <option value="11:00">11:00</option>
          <option value="11:30">11:30</option>
          <option value="12:00">12:00</option>
          <option value="12:30">12:30</option>
          <option value="13:00">13:00</option>
          <option value="13:30">13:30</option>
          <option value="14:00">14:00</option>
          <option value="14:30">14:30</option>
          <option value="15:00">15:00</option>
          <option value="15:30">15:30</option>
          <option value="16:00">16:00</option>
          <option value="16:30">16:30</option>
          <option value="17:00">17:00</option>
          <option value="17:30">17:30</option>
          <option value="18:00">18:00</option>
          <option value="18:30">18:30</option>
          <option value="19:00">19:00</option>
          <option value="19:30">19:30</option>
          <option value="20:00">20:00</option>
          <option value="20:30">20:30</option>
          <option value="21:00">21:00</option>
          <option value="21:30">21:30</option>
          <option value="22:00">22:00</option>
          <option value="22:30">22:30</option>
          <option value="23:00">23:00</option>
          <option value="23:30">23:30</option>
        </select>
      </div>
      
      <div class="form-group">
        <label for="passengers">Passengers:</label>
        <div class="passengers-dropdown" (click)="toggleDropdown()">
          <input type="text" id="passengers" readonly [value]="getPassengerSummary()" />
        </div>
      </div>

      <div class="dropdown-menu" *ngIf="isDropdownOpen">
        <div class="form-group">
          <label for="adults">Adults:</label>
          <select id="adults" name="adults" [(ngModel)]="passengerDetails.adults">
            <option *ngFor="let adult of adultsArray" [value]="adult">{{ adult }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="children">Children:</label>
          <select id="children" name="children" [(ngModel)]="passengerDetails.children" (change)="updateChildrenAges()">
            <option *ngFor="let child of childrenArray" [value]="child">{{ child }}</option>
          </select>
        </div>

        <div *ngFor="let child of childAgeArray; let i = index" class="form-group">
          <label for="child-age-{{i}}">Child {{i + 1}} Age:</label>
          <select id="child-age-{{i}}" [(ngModel)]="childAgeArray[i]" name="child-age-{{i}}">
            <option *ngFor="let age of childrenAgeArray" [value]="age">{{ age }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="infants">Infants:</label>
          <select id="infants" name="infants" [(ngModel)]="passengerDetails.infants">
            <option *ngFor="let infant of infantsArray" [value]="infant">{{ infant }}</option>
          </select>
        </div>

        <!-- <div *ngIf="!isValidSelection" class="validation-error">
          Please select one hotel and one terminal for pick-up and drop-off locations.
        </div> -->

        <button type="button" class="done-btn" (click)="toggleDropdown()">Done</button>
      </div>

      <div>
        <p (click)="toggleReturnInfo()"> {{ showReturnInfo ? 'Cancel Return' : 'Add Return' }} </p>
        <div class="return-info" *ngIf="showReturnInfo">
          <div class="form-group">
            <label for="date">Return Date:</label>
            <input matInput #drp="bsDatepicker" bsDatepicker [(ngModel)]="bsRangeValueReturn" 
            placeholder="Check in - Check out"
            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD MMM', showWeekNumbers: false ,displayMonths: 2}"
            (bsValueChange)="onDropOffTransfer($event)" [minDate]="minDate">
          </div>
          
          <!-- <div class="form-group">
            <label for="date">Date:</label>
            <input matInput #drp="bsDatepicker" bsDatepicker [(ngModel)]="bsRangeValue" formControlName="date"
            placeholder="Check in - Check out"
            [bsConfig]="{ isAnimated: true, rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', minDate: initialDate, maxDate: maxYearDate, showWeekNumbers: false, preventChangeToNextMonth: true}"
            (bsValueChange)="onDateRangeChange($event)">
          </div>
           -->
          <div class="form-group">
            <label for="time">return Time:</label>
            <select id="time" name="returnTime" [(ngModel)]="dropOffTime" (ngModelChange)="onDropOffTimeChange($event)">
              <!-- Options for time -->
              <option value="00:00">00:00</option>
              <option value="00:30">00:30</option>
              <option value="01:00">01:00</option>
              <option value="01:30">01:30</option>
              <option value="02:00">02:00</option>
              <option value="02:30">02:30</option>
              <option value="03:00">03:00</option>
              <option value="03:30">03:30</option>
              <option value="04:00">04:00</option>
              <option value="04:30">04:30</option>
              <option value="05:00">05:00</option>
              <option value="05:30">05:30</option>
              <option value="06:00">06:00</option>
              <option value="06:30">06:30</option>
              <option value="07:00">07:00</option>
              <option value="07:30">07:30</option>
              <option value="08:00">08:00</option>
              <option value="08:30">08:30</option>
              <option value="09:00">09:00</option>
              <option value="09:30">09:30</option>
              <option value="10:00">10:00</option>
              <option value="10:30">10:30</option>
              <option value="11:00">11:00</option>
              <option value="11:30">11:30</option>
              <option value="12:00">12:00</option>
              <option value="12:30">12:30</option>
              <option value="13:00">13:00</option>
              <option value="13:30">13:30</option>
              <option value="14:00">14:00</option>
              <option value="14:30">14:30</option>
              <option value="15:00">15:00</option>
              <option value="15:30">15:30</option>
              <option value="16:00">16:00</option>
              <option value="16:30">16:30</option>
              <option value="17:00">17:00</option>
              <option value="17:30">17:30</option>
              <option value="18:00">18:00</option>
              <option value="18:30">18:30</option>
              <option value="19:00">19:00</option>
              <option value="19:30">19:30</option>
              <option value="20:00">20:00</option>
              <option value="20:30">20:30</option>
              <option value="21:00">21:00</option>
              <option value="21:30">21:30</option>
              <option value="22:00">22:00</option>
              <option value="22:30">22:30</option>
              <option value="23:00">23:00</option>
              <option value="23:30">23:30</option>
            </select>
          </div>
        </div>
      </div>
      <script>
        function toggleDropdown() {
            const dropdown = document.getElementById('passengerDropdown');
            dropdown.classList.toggle('active');
        }
    </script>

    <div *ngIf="errorMessages.terminalHotelError">
      <p style="color: red">{{ errorMessages.terminalHotelError }}</p>
    </div>

    <div *ngIf="errorMessages.requiredFields">
      <p style="color: red">{{ errorMessages.requiredFields }}</p>
    </div>

<!-- <button type="submit" class="search-btn" (click)="OnSearch()" [disabled]="!selectedTransfer || !selectedDropoffTransfer || !checkIn || !pickUpTime || !passengerDetails">Search</button> -->
      <button type="submit" class="search-btn" (click)="OnSearch()">Search</button>
    </form>
  </div>
</div>
