<section class="location">
  <h1 class="font-poppins">Our Locations</h1>
  <div class="row gap-8">
    <div class="col-md-4 firstLoc font-poppins">
      <img [src]="'assets/images/londonbridge.jpg'" alt="" class="icon" />
      <h4 class="mt-10 flex justify-center text-center">United Kingdom<img [src]="'assets/images/united-kingdom.png'" alt="" class="flagIcon" /></h4>
      <p class="px-5">128 Evington Road, Leicester, LE2 1HL United Kingdom</p>
    </div>
    <div class="col-md-4 secondLoc font-poppins">
      <img [src]="'assets/images/tajmahal.jpg'" alt="" class="icon" />
      <h4 class="mt-10 flex justify-center text-center">India <img [src]="'assets/images/india.png'" alt="" class="flagIcon" /></h4>
      <p class="px-5">
        Office No 6, Level 1, Dewan Center, S.V. Road Jogeshwari West Mumbai
        400102
      </p>
    </div>
    <div class="col-md-4 thirdLoc font-poppins">
      <img [src]="'assets/images/dubaimarina.jpg'" alt="" class="icon" />
      <h4 class="mt-10 flex justify-center text-center">UAE <img [src]="'assets/images/UAE1.png'" alt="" class="dubaiFlagIcon" /></h4>
      <p class="px-5">
        Bridge Tower, 3rd Floor Dubai Sports City P.O. Box 392269, Dubai; UAE
      </p>
    </div>
  </div>
</section>