<app-splashScreen *ngIf="loadingService?.isLoading$ | async"></app-splashScreen>
<app-header [logo]="image?.ratelocLogo" [section1]="'Dashboard'" [section2]="'My Bookings'" [section3]="''"
    *ngIf="!(loadingService?.isLoading$ | async)"></app-header>
<div class="bookingSummary" *ngIf="!(loadingService?.isLoading$ | async) && ((statusCode == 1000 || bookingAccommodation?.supplier == '111' || bookingAccommodation?.supplier == '222' || bookingAccommodation?.supplier == '333' || bookingAccommodation?.supplier == '444'))"  [class.blur]="loader">
    
    <button class="btn backIcon" (click)="BackIcon()"><i class="fa fa-angle-left" ></i> Back</button>

    <div class="container fontSty mt-20 pb-6">
        <form class="row" [formGroup]="addUserForm" (ngSubmit)="onAddUser()">
            <div class="row ">
                <div class="col-md-8 scrollable  mt-3">
                    <div class="card">
                        <div class="card-body text-capitalize">
                            <div class="d-flex justify-content-between">
                                <h5 class="h5 mb-3"> <strong>information about guest</strong></h5>
                                <div class=""><i class="fa-solid fa-stopwatch timer me-1"></i><span class="timer">{{minutes}}.{{ seconds < 10? '0' + seconds : seconds }}</span> <i
                                    class="fa fa-info-circle ms-2" [matTooltip]="'You need to make the booking before time expires'" matTooltipPosition="right" aria-hidden="true"></i></div>
                            </div>
                            <!-- <h6 class="h6 fw-bold">room 1 : {{bookingAccommodation?.roomName}}</h6> -->
                            <ng-container formArrayName="rooms">
                                <div class="" *ngFor="let item of addUserForm?.controls?.rooms?.controls; index as i">
                                    <div class="" [formGroupName]="i">
                                        <!-- <div class="flex flex-row w-100 p-1"> -->
                                        <!-- <label class="text-sm col-md-5 text-left">Rooms:</label>
                                      <select formControlName="room" class="text-center border">
                                        <option *ngFor="let value of [1, 2, 3, 4, 5]" [value]="value">{{ value }}</option>
                                      </select> -->
                                        <p class="fw-bold mt-3">Room {{i+1}} : {{isCart ? item?.value.roomInformation?.roomName : bookingAccommodation?.roomName}} ({{isCart ? item?.value.roomInformation?.boardBase : bookingAccommodation?.boardBase}})</p>
                                        <!-- </div> -->
                                        <!-- </mat-form-field> -->


                                        <div *ngIf="item?.controls?.paxes?.length >= 0">
                                            <ng-container formArrayName="paxes">
                                                <div *ngFor="let user of item?.controls?.paxes?.controls; index as i"
                                                    class="mt-3">
                                                    <div [formGroupName]="i" class="d-flex">
                                                        <div class="col-md-2 mx-2">
                                                            <label for="inputState" class="form-label">Title<sup
                                                                    class="text-danger">*</sup></label>
                                                            <select id="inputState" class="form-select inputState "
                                                                formControlName="title">
                                                                <option value="Mr" selected>Mr.</option>
                                                                <option value="Miss">Miss</option>
                                                                <option value="Mrs">Mrs</option>
                                                            </select>

                                                        </div>
                                                        <!-- <div
                                                        *ngIf="addUserForm.get('tittle')?.invalid && (addUserForm.get('tittle')?.dirty || addUserForm.get('tittle')?.touched)">
                                                        <div class="text-danger">
                                                            tittle is required
                                                        </div>
                                                    </div> -->
                                                        <div class="col-md-4 mx-2">
                                                            <label for="fname" class="form-label">first name <sup
                                                                    class="text-danger">*</sup></label>
                                                            <input type="text" (keypress)="onKeyPress($event,true)" class="form-control placeholder-font"
                                                                id="" formControlName="fname" placeholder="Enter Name">
                                                            <!-- <div *ngIf="(user.get('fname')?.invalid) && (user.get('fname')?.dirty || user.get('fname')?.touched || submitted)"
                                                            class="text-danger">
                                                            <p> first name is required </p>
                 [ngClass]="{'is-invalid':(user.get('fname')?.invalid) && (user.get('fname')?.dirty || user.get('fname')?.touched || submitted)}"
                                                        </div> -->
                                                        </div>

                                                        <!-- <div class="col-md-3 mx-1">
                                                        <label for="middleName" class="form-label">middle name <sup
                                                                class="text-danger">*</sup></label>
                                                        <input type="text" class="form-control placeholder-font" id=""
                                                            formControlName="middleName" placeholder="Enter Middle Name"
                                                            [ngClass]="{'is-invalid':(user.get('middleName')?.invalid) && (user.get('middleName')?.dirty || user.get('middleName')?.touched || submitted)}">
                                                            <div *ngIf="(user.get('middleName')?.invalid) && (user.get('middleName')?.dirty || user.get('middleName')?.touched || submitted)"
                                                            class="text-danger">
                                                            <p> middleName is required </p>
                
                                                        </div>
                                                        </div> -->


                                                        <div class="col-md-4 mx-2">
                                                            <label for="lname" class="form-label">last name<sup
                                                                    class="text-danger">*</sup></label>
                                                            <input (keypress)="onKeyPress($event)" type="text" class="form-control placeholder-font"
                                                                id="" formControlName="lname"
                                                                placeholder="Enter Last Name">
                                                            <!-- <div *ngIf="(user.get('lname')?.invalid) && (user.get('lname')?.dirty || user.get('lname')?.touched || submitted)"
                                                            class="text-danger">
                                                            <p> lname is required </p>
                 [ngClass]="{'is-invalid':(user.get('lname')?.invalid) && (user.get('lname')?.dirty || user.get('lname')?.touched || submitted)}"
                                                        </div> -->
                                                        </div>

                                                        <div class="col-md-1 mx-2">
                                                            <p class=" badgeType">{{user?.controls?.type?.value == 'adults'
                                                                ? 'Adult' : 'Child' }}</p>
                                                            <p *ngIf="user?.controls?.type?.value == 'children'" >{{user?.controls.childAges.value}} Years</p>


                                                        </div>


                                                        <!-- <div class="col-md-1 mt-9 ml-1 px-1" *ngIf="i > 0">
                                                        <button (click)="deleteItem(i)">
                                                            <strong data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Remove Names Of Other Guest"> <i
                                                                    class="fa fa-trash fa-1x text-danger"></i> </strong>
                                                        </button>
            
                                                    </div> -->


                                                    </div>
                                                    <!-- 
            
                                                <p *ngIf="i % 2 === 1 && i>0" class=" mt-2">
                                                    --------------------------------------------- <strong class="guestAdd p-2"> the
                                                        {{i+1}} guest added</strong>----------------------------------</p> -->
                                                </div>

                                                <!-- 
                                            <div class="col-md-5 mt-3">
                                                <strong (click)="addcustomer()" class="cursor-pointer">
                                                    <i class="fa fa-user-plus fa-1x"></i> add name of other guest</strong>
                                            </div> -->
                                            </ng-container>
                                            <div
                                                *ngIf="(addUserForm?.controls?.rooms?.invalid) && (addUserForm?.controls?.rooms?.dirty || addUserForm?.controls?.rooms?.touched || submitted)">
                                                <p class="text-danger">required all inputs</p>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                            </ng-container>




                        </div>
                    </div>
                    <table class="role-data-table mt-2" *ngIf="bookingAccommodation?.roleData">
                        <thead>
                          <tr>
                            <th *ngIf="bookingAccommodation?.roleData?.supplier">Supplier</th>
                            <th>Net Price</th>
                            <th>Selling Price</th>
                            <th>Margin</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td *ngIf="bookingAccommodation?.roleData?.supplier">{{ bookingAccommodation?.roleData?.supplier }}</td>
                            <td>{{ (["222","444","555","666"].includes(bookingAccommodation?.supplier)? bookingAccommodation?.roleData?.netPrice*ratehawkApiCurrencyValue :bookingAccommodation?.roleData?.netPrice*apiCurrencyValue) | currency: currencyCode }}</td>
                            <td>{{ (["222","444","555","666"].includes(bookingAccommodation?.supplier)? bookingAccommodation?.roleData?.sellingPrice*ratehawkApiCurrencyValue :bookingAccommodation?.roleData?.sellingPrice*apiCurrencyValue) | currency: currencyCode }}</td>
                            <td>{{ (["222","444","555","666"].includes(bookingAccommodation?.supplier)? bookingAccommodation?.roleData?.margin*ratehawkApiCurrencyValue :bookingAccommodation?.roleData?.margin*apiCurrencyValue) | currency: currencyCode }}</td>
                          </tr>
                        </tbody>
                      </table>

                    <div class="card mt-2">
                        <div class="card-body">
                            <h5 class="h4"> <strong>
                                    Manage Your Booking</strong></h5>
                            <div class="col-md-4">
                                <label for="email" class="form-label text-muted">Email Address <sup
                                        class="text-danger">*</sup></label>
                                <input type="text" class="form-control placeholder-font" placeholder="rateloc@email.com"
                                    formControlName="email"
                                    [ngClass]="{'is-invalid':addUserForm?.controls?.email?.invalid && (addUserForm?.controls?.email?.touched ||addUserForm?.controls?.email?.dirty || submitted )}">
                            </div>
                            <div *ngIf="addUserForm?.get('email')?.invalid && (addUserForm?.get('email')?.dirty || addUserForm?.get('email')?.touched)"
                                class="text-danger mt-1">
                                <div
                                    *ngIf="addUserForm?.get('email')?.errors?.required && addUserForm?.get('email')?.invalid || submitted">
                                    Email is required</div>
                                <div *ngIf="addUserForm?.get('email')?.errors?.invalidEmail">Invalid email Format</div>
                            </div>
                            <div class="form-check mt-2">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    formControlName="offers">
                                <label class="form-check-label " for="flexCheckDefault" class="form-label text-dark">
                                    I would like to receive offers & promotions from RateLoc. I understand that my details will not be shared with third parties for promotional purposes. </label>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4">
                                    <div class="form group">
                                        <label for="country" class="form-label">contry/territory code <sup
                                                class="text-danger">*</sup></label>
                                        <input id="country" class="form-control" formControlName="country" readonly>
                                    </div>
                                    <div
                                        *ngIf="addUserForm?.get('country')?.invalid && (addUserForm?.get('country')?.dirty && addUserForm?.get('country')?.touched)">
                                        <div class="text-danger">
                                            country is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="phone number" class="form-label">Phone Number<sup
                                            class="text-danger">*</sup></label>
                                    <input type="text" class="form-control placeholder-font"
                                        placeholder="Enter Phone Number" formControlName="phone" readonly
                                        [ngClass]="{'is-invalid': addUserForm?.controls?.phone?.invalid && (addUserForm?.controls?.phone?.touched || addUserForm?.controls?.phone?.dirty || submitted )}">
                                    <div
                                        *ngIf="addUserForm?.get('phone')?.invalid && (addUserForm?.get('phone')?.dirty || addUserForm?.get('phone')?.touched || submitted)">
                                        <div class="text-danger"
                                            *ngIf="addUserForm?.get('phone')?.errors?.invalidPhoneNumber">
                                            Only numbers are allowed for the phone number.
                                        </div>
                                        <div class="text-danger" *ngIf="addUserForm?.get('phone')?.errors?.required">
                                            Phone number is required.
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div class="col-md-8 mt-3">
                                <label for="specialR" class="form-label">special request</label>
                                <textarea name="" id="" cols="60" rows="3" class="form-control"
                                    formControlName="specialRequest"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-2">



                        <div class="card-body">
                            <h5 class="h5 fw-bold">Payment Method</h5>
                            <span class="text-danger" *ngIf="temporaryDateFix">Add to account is not available because deadline is before current date</span>
                            <div class="border">

                                <div [ngClass]="{'PaymentBox' :paymentMethod=='manual', 'disabled-box': !refundable}"
                                    class="cursor-pointer p-2">
                                    <div (click)="paymentMethods('manual')"
                                        class="d-flex text-center paymentMethodInput">
                                        <input type="radio" [(ngModel)]="paymentMethod"
                                            [ngModelOptions]="{standalone: true}" value="manual" class="mx-2"
                                            [disabled]="!refundable">

                                        <h6 class="h6"> <strong>Add to account</strong></h6>

                                        <p class="badge bg-secondary d-block ml-2">{{currencyCode}}</p>
                                    </div>
                                    <p class="ml-7">This payment method is only available for the rates of corresponding
                                        payment conditions</p>
                                </div>

                                <div (click)="paymentMethods('payByCard')"
                                    [ngClass]="{'PaymentBox' : paymentMethod=='payByCard'}"
                                    class="cursor-pointer p-2 paymentMethodInput">
                                    <div class="d-flex">

                                        <input type="radio" [(ngModel)]="paymentMethod"
                                            [ngModelOptions]="{standalone: true}" value="payByCard" class="mx-2"
                                            [checked]="!refundable">
                                        <h6 class="h6 " class="cursor-pointer"> <strong> Pay By Card </strong></h6>
                                    </div>

                                    <P class="ml-7 mb-0">The booking cost will be withdrawn from your bank card after the
                                        booking
                                        is made</P>
                                    <P class="p-0 ml-7" style="color: rgb(255, 75, 75);">Note - an additional charge up
                                        to
                                        4.4%
                                        will be applied on card payment.</P>
                                    <!-- <div class="btn btn-warning btn-sm mb-3 text-white">
                                        <span class="ps-3">Pay $ {{ calculateTotalPrice() }}</span>
                                        <span class="fa fa-arrow-right"></span>
                                    </div> -->
                                    <!-- <div class="text-center">
                                        <button class="btn mt-3 text-capitalize detailBtn ">Pay $ {{ calculateTotalPrice() }}
                                        </button>
                                    </div> -->
                                </div>
                            </div>

                            <!-- <div *ngIf="paymentMethod=='payByCard'" class="mt-2">

                                <div class="col-12">
                                    <div class="d-flex flex-column">
                                        <p class="text mb-1">Card Holder Name</p>
                                        <input class="form-control mb-3" type="text" placeholder="Name" value="rateloc">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex flex-column">
                                        <p class="text mb-1">Card Number</p>
                                        <input class="form-control mb-3" type="text" placeholder="1234 5678 435678">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="d-flex flex-column">
                                        <p class="text mb-1">Expiry</p>
                                        <input class="form-control mb-3" type="text" placeholder="MM/YYYY">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="d-flex flex-column">
                                        <p class="text mb-1">CVV/CVC</p>
                                        <input class="form-control mb-3 pt-2 " type="password" placeholder="***">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="btn btn-warning btn-sm mb-3 text-white">
                                        <span class="ps-3">Pay $243</span>
                                        <span class="fas fa-arrow-right"></span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                </div>
                <div class="col-md-4 scrollable mt-3">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="h5 fw-bold">{{bookingAccommodation?.title}}</h5>
                            <h6 class="h6">{{bookingAccommodation?.address}}</h6>
                            <!-- <span class="fw-bold">{{bookingAccommodation?.boardBase}}</span> -->

                            <div class="row mb-3">
                                <div class="rating mt-0 col-md-6">
                                    <span *ngIf="rating">
                                        <span
                                            *ngFor="let star of getStarsArray(getNumericRating(bookingAccommodation?.rating)!)">
                                            <i class="fa fa-star orange-color p-0.5 fa-1.5x" aria-hidden="true"></i>
                                        </span>
                                    </span>
                                </div>
                                <div class="col-md-6 text-end">
                                    <span class="badge" [ngStyle]="{'color': refundable ? '#A2C030' : '#CF0A0A'}">
                                        {{ refundable ? 'Free cancellation available' : 'No free cancellation available' }}
                                    </span>
                                </div>
                            </div>

                            <img class="paymentImg" [src]='bookingAccommodation?.images' alt="img" title="img">
                            <h6 class="h6 mt-3 fw-bold">{{bookingAccommodation?.roomName}}</h6>
                            <ng-container *ngIf="bookingAccommodation?.amenities.length>0">

                            
                            <h6 class="h6 mt-3">Amenities</h6>
                            <span class="mr-3 " *ngFor="let ammenity of bookingAccommodation?.amenities?.slice(0,1)"> <span class="text-warning"> &#10003; </span>{{ammenity}} </span>
                            <span class="text-primary" (mouseover)="onAmmenities()" (mouseleave)="onAmmentiesLeave()">
                                +22 more</span>
                        </ng-container>

                            <div *ngIf="AmmenitiesShow" class=" AmmenitiesShow ">
                                <div class="row">
                                    <div class="col-md-6">
                                        <ul class="noBullets" *ngFor="let s of bookingAccommodation?.amenities?.slice(0,11)">
                                            <li>
                                                {{s.slice(0,15)}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6" >
                                        <ul class="noBullets"  *ngFor="let t of bookingAccommodation?.amenities?.slice(11,22)">
                                            <li>
                                                {{t.slice(0,15)}}
                                            </li>                                           
                                        </ul>
                                    </div>                                 
                                </div>
                            </div>

                        </div>

                        <div class="d-flex justify-content-between dateBox p-3">
                            <div>
                                <p class="small m-0 p-0 text-muted">Check in</p>
                                <strong class="dateFont m-0 p-0 text-dark">{{checkInDate
                                    | date:'dd MMM'}}</strong>
                                <p class="small m-0 p-0 text-muted">{{bookingAccommodation?.getListRequest.checkIn}}</p>
                            </div>
                            <div>
                                <p class="small m-0 p-0 text-muted">Check out</p>
                                <strong class=" m-0 p-0 dateFont">{{checkOutDate |
                                    date:'dd MMM'}}</strong>
                                <p class="small m-0 p-0 text-muted">{{this.bookingAccommodation?.getListRequest?.checkOut}}</p>
                            </div>
                            <div>
                                <p class="small m-0 p-0 text-muted">Guest</p>
                                <p class="dateFont m-0 p-0 fw-bold">{{getTotalGuest()}} Guest</p>
                                <p class="small m-0 p-0 text-muted">{{this.addUserForm?.controls?.rooms?.length}} Rooms</p>

                            </div>
                        </div>
                        <div class="card-body">

                            <div class="d-flex justify-content-between mt-2 p-0 dateFont">
                                <p class="text-muted text-sm">Price of room per night </p>
                                <strong *ngIf="!isCart">{{(["222","444","555","666"].includes(bookingAccommodation?.supplier)? bookingAccommodation?.priceList?.perNightPrice*ratehawkApiCurrencyValue :bookingAccommodation?.priceList?.perNightPrice*apiCurrencyValue) |
                                    currency: currencyCode}}</strong>
                                <strong *ngIf="isCart">{{(["222","444","555","666"].includes(bookingAccommodation?.supplier)? (perNightPrice*ratehawkApiCurrencyValue).toFixed(2) :(perNightPrice*apiCurrencyValue).toFixed(2)) |
                                    currency: currencyCode}}</strong>
                            </div>
                            <div class="d-flex justify-content-between p-0 dateFont">
                                <p class="text-muted text-sm">Total price </p>
                                <strong *ngIf="!isCart">{{(["222","444","555","666"].includes(bookingAccommodation?.supplier)? bookingAccommodation?.priceList?.totalPrice*ratehawkApiCurrencyValue :bookingAccommodation?.priceList?.totalPrice*apiCurrencyValue) |
                                    currency:currencyCode}}</strong>
                                <strong *ngIf="isCart">{{(["222","444","555","666"].includes(bookingAccommodation?.supplier)? (totalPrice*ratehawkApiCurrencyValue).toFixed(2) :(totalPrice*apiCurrencyValue).toFixed(2)) |
                                    currency:currencyCode}}</strong>
                            </div>
                            <div class="d-flex justify-content-between mb-2 p-0 dateFont" *ngIf="!refundable || paymentMethod == 'payByCard'">
                                <p class="text-muted text-sm">Card Fees</p>
                                <strong>{{calculatePercentOfTotalPrice() |
                                    currency:currencyCode}}</strong>
                            </div>
                            <hr>
                            <p *ngIf="bookingAccommodation?.remarks?.length > 0" (click)="onShowImpInfo()" class="cursor-pointer text-red-600 font-bold">Show important info</p>
                            <p *ngIf="bookingAccommodation?.paymentType == 'Refundable'" (click)="onCancellation()" class="cursor-pointer text-muted">Cancellation policy
                            </p>
                            <div  class="container mb-3" *ngIf="cancellationPolicy ">
                                <p *ngIf="bookingAccommodation?.paymentType == 'Refundable' && refundable" class="text-danger m-0 p-0">Free cancellation is applicable until {{bookingAccommodation?.deadline}}.</p>
                                <!-- <ng-container *ngFor="let item of bookingAccommodation?.extraCharges; index as i">
                                    <div class="d-flex justify-content-between" [ngClass]="{'mt-3': i == 0, 'mb-3': i == bookingAccommodation?.extraCharges.length - 1}">
                                        <p class=" p-0 mb-0 mt-1">{{item.name}}</p>
                                    <p class="text-danger me-4 mb-0 p-0 mt-1">{{item.amount}}</p>
                                    </div>
                                </ng-container> -->
                                <input class="me-2" type="checkbox" formControlName="cancellation">
                                <span *ngIf="bookingAccommodation?.paymentType == 'Refundable'">I hereby acknowledge and accept the cancellation policy for this booking, as well as the <strong
                                        class="text-primary">terms & conditions </strong></span>
                                <span *ngIf="bookingAccommodation?.paymentType != 'Refundable'">I hereby acknowledge and accept the <strong
                                        class="text-primary">terms & conditions </strong></span>
                                        <div 
                                        *ngIf="(addUserForm?.controls?.cancellation?.invalid || !addUserForm?.controls?.cancellation?.value) && (addUserForm?.controls?.cancellation?.dirty || addUserForm?.controls?.cancellation?.touched || submitted)">
                                        <p  class="text-danger">{{ bookingAccommodation?.paymentType == 'Refundable' ? "* Please accept the cancellation policy" : "Please accept the terms and condition"}}</p>
                                    </div>
                                        


                            </div>
                            <div class="d-flex justify-content-between dateFont">
                                <strong>Total payable </strong>
                                <ng-container *ngIf="isCart">
                                    <strong *ngIf="refundable && paymentMethod!='payByCard'">{{(["222","444","555","666"].includes(bookingAccommodation?.supplier)? cartRatehawkConvertedPrice.toFixed(2) : cartConvertedPrice.toFixed(2)) |
                                        currency: currencyCode}}</strong>
                                        <strong *ngIf="!refundable || paymentMethod=='payByCard'">{{ calculateTotalPrice() |
                                        currency: currencyCode}}</strong>
                                </ng-container>
                                <ng-container *ngIf="!isCart">
                                    <strong *ngIf="!refundable  || paymentMethod=='payByCard'">{{ calculateTotalPrice() |
                                        currency: currencyCode}}</strong>
                                    <strong *ngIf="refundable && paymentMethod!='payByCard'">{{(["222","444","555","666"].includes(bookingAccommodation?.supplier)? bookingAccommodation?.priceList?.totalPrice*ratehawkApiCurrencyValue :bookingAccommodation?.priceList?.totalPrice*apiCurrencyValue) |
                                        currency: currencyCode}}</strong>
                                </ng-container>
                            </div>
                            <div class="text-center">
                                <button class="btn mt-3  text-capitalize detailBtn " type="submit"
                                    [disabled]="apiInProgress">Book Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="loader" class="loader">
    <app-loader></app-loader>
    <p>Please wait while we confirm your booking</p>
</div>

<ng-template #restrictNotice>
    <div class="crossButton">
        <button type="button" class="close " aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="larger-cross">
                <i class="fa fa-times"></i> <!-- Font Awesome cross icon -->
            </span>
        </button>
    </div>
    <div class="content restrict d-flex align-items-center font-poppins pt-0">
      <div class="center-grid">
        <h5 class="mt-3 text-center">To make a booking, you need to complete the onboarding process. For any queries, please reach out to support or email
            <a>{{'help@rateloc.com'}}</a></h5>
      </div>
    </div>
</ng-template>


<ng-template #bookingTimeoutModal>
    <!-- <div class="modal fade" id="sessionExpiredModal" tabindex="-1" aria-labelledby="sessionExpiredModalLabel" aria-hidden="true"> -->
        <!-- <div class="modal-dialog modal-dialog-centered"> -->
          <div class="modal-content">
            <div class="modal-header py-2 detailBtn text-white">
              <h5 class="modal-title" id="sessionExpiredModalLabel">Booking time Expired</h5>
              <button type="button" class="btn-close" (click)="dis()" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
              <div>
                <i class="fa-regular fa-clock detailBtn" style="font-size: 2rem; color: #ec6408;"></i>
              </div>
              <p class="mt-3">Your booking time has expired.<br>You will be redirected to the Listing page.</p>
            </div>
            <!-- <div class="modal-footer d-flex justify-content-center">
              <button type="button" class="btn detailBtn" (click)="dis()">Go back to Listing</button>
            </div> -->
          </div> 
        <!-- </div> -->
      <!-- </div> -->
  </ng-template>

<ng-template #impModal>
    <div class="content font-poppins">
      <div class="center-grid">
        <p class="message-type mt-3 text-center" *ngFor="let x of bookingAccommodation?.remarks">• {{x}}</p>
        <button class="btn main-button" (click)="closeModal()">Close</button>
      </div>
    </div>
</ng-template>

<ng-template #notFound>
    <div class="crossButton">
        <button type="button" class="close " aria-label="Close" (click)="dis()">
            <span aria-hidden="true" class="larger-cross">
                <i class="fa fa-times"></i> <!-- Font Awesome cross icon -->
            </span>
        </button>
    </div>
    
    <div *ngIf="statusCode == 1100" class="container font-poppins py-10">
        <div class="col-sm-12">
            <div class="col-sm-12 col-sm-offset-1 text-center">
              <div class="bg-img">
                <h1 class="text-center">404</h1>
              </div>
              <div class="content">
                <h3 class="h2">No hotels Found!</h3>
                <p>are you sure you want to be here?</p>
                <a (click)="dis()" class="link">Go back to Listing</a>
              </div>
            </div>
          </div>
    </div>
    
    <div *ngIf="statusCode == 1200" class="container font-poppins py-10">
        <div class="col-sm-12">
            <div class="col-sm-12 col-sm-offset-1 text-center">
              <div class="bg-img">
                <h1 class="text-center">404</h1>
              </div>
              <div class="content">
                <h3 class="h2">Oops something went wrong</h3>
                <p>are you sure you want to be here?</p>
                <a (click)="dis()" class="link">Go back to Listing</a>
              </div>
            </div>
          </div>
    </div>
</ng-template>

