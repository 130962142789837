import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private baseUrls = this.configService?.getApiUrl();
  private breadCrumb = this.configService?.getBreadcrumb();
  private searchQuerySubject = new BehaviorSubject<any>('');
  private detailQuerySubject = new BehaviorSubject<any>('');
  private bookingQuerySubject = new BehaviorSubject<any>('');
  private currentQuery: any = null;
  public searchQuery$: Observable<any> = this.searchQuerySubject?.asObservable();

  constructor(private http: HttpClient, private configService: ConfigService) { }

  loginAuthentication(obj: any): Observable<any> {
    return this.http?.post(this.baseUrls + this.breadCrumb?.login, obj);
  }
  registerAuthentication(obj: any): Observable<any> {
    return this.http?.post(this.baseUrls + this.breadCrumb?.register, obj);
  }
  forgotPassword(email: any): Observable<any> {
    const url = `${this.baseUrls}${this.breadCrumb?.forgotPassword}?email=${email}`;
    return this.http?.put(url, {});
  }

  updateSearchQuery(query: any): void {
    this.currentQuery = query;
    this.searchQuerySubject?.next(query);
  }

  getCurrentQuery(): any {
    return this.currentQuery;
  }
  updateDetailQuery(query: any): any {
    this.detailQuerySubject?.next(query);
  }
  getDetailQuery(): any {
    return this.detailQuerySubject;
  }

  updateBookingQuery(query: any): any {
    this.bookingQuerySubject?.next(query);
  }

  getBookingQuery(): any {
    return this.bookingQuerySubject;
  }

  getListHappy(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListHappy, obj, {
      headers,
    });
  }


  
  getListMgBedBank(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListMgBedBank, obj, {
      headers,
    });
  }

  
  getListGoGlobal(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListGoGlobal, obj, {
      headers,
    });
  }

  
  getListDida(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListDida, obj, {
      headers,
    });
  }
  getListYalago(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListYalago, obj, {
      headers,
    });
  }
  getListTbo(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListTbo, obj, {
      headers,
    });
  }

  getAllSupplier(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.get(this.baseUrls + this.breadCrumb?.getAllSupplier, {headers});
  }

  getListRatehawk(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListRatehawk, obj, {
      headers,
    });
  }

  hotelsSearch(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.hotelsSearch, obj, {
      headers,
    });
  }
  getListStuba(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListStuba, obj, {
      headers,
    });
  }
  getListHotelBeds(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.getListHotelBeds, obj, {
      headers,
    });
  }

  bookingCancel(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.post(this.baseUrls + this.breadCrumb?.bookingCancel, obj, {
      headers,
    });
  }

  transfersBookingCancel(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.post(this.baseUrls + this.breadCrumb?.transfersBookingCancel, obj, {
      headers,
    });
  }

  getSearchList(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.post(this.baseUrls + this.breadCrumb?.getSearchList, obj, {
      headers,
    });
  }
  getDetail(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.post(this.baseUrls + this.breadCrumb?.getDetail, obj, {
      headers,
    });
  }

  updateBooking(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.post(this.baseUrls + this.breadCrumb?.updateBooking + '/' + sessionStorage.getItem('bookingRefId'), obj, {
      headers,
    });
  }

  updateCurrency(baseCurrency: any, symbolCurrency: any): Observable<any> {
    return this.http.get('https://api.exchangeratesapi.io/v1/latest?access_key=176e499bbf0d29fd3d9610b43553d44b&base=' + baseCurrency +'&symbols=' + symbolCurrency);
  }

  stripeBooking(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });

    return this.http?.post(this.baseUrls + this.breadCrumb?.stripeBooking, obj, {
      headers,
    });
  }
  paypalExecute(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });

    return this.http?.post(this.baseUrls + this.breadCrumb?.paypalExecute, obj, {
      headers,
    });
  }

  booking(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });

    return this.http?.post(this.baseUrls + this.breadCrumb?.booking, obj, {
      headers,
    });
  }

  transferBooking(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });

    return this.http?.post(this.baseUrls + this.breadCrumb?.transferBooking, obj, {
      headers,
    });
  }

  bookingSummary(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.post(this.baseUrls + this.breadCrumb?.bookingSummary, obj, {
      headers,
    });
  }

  resetPassword(obj: any): Observable<any> {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   Authorization: `Bearer ${sessionStorage['tokenId']}`,
    // });
    return this.http?.post(this.baseUrls + this.breadCrumb?.resetPassword + sessionStorage?.getItem('forgotEmail'), obj, {
    });
  }

  bookingHistory(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.get(this.baseUrls + this.breadCrumb?.bookingHistory + '/' + Number(sessionStorage?.getItem('userId')), {
      headers,
    });
  }

  transfersBookingHistory(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.get(this.baseUrls + this.breadCrumb?.transfersBookingHistory + '/' + Number(sessionStorage?.getItem('userId')), {
      headers,
    });
  }

  searchLocation(query: string): Observable<any> {
    let apiUrl =
      'https://api.travelify.io/autocomplete?query=' +
      query +
      '&split=false&searchType=2&language=en&minStarRating=4';
    // let apiUrl = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+query+'&key=AIzaSyAD781vlJcO7aVuy_LJuN0mzwJ8uM8Ow-s'
    return this.http?.get(apiUrl);
  }

  getHotels(value: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.get(this.baseUrls + this.breadCrumb?.hotels + value, {
      headers,
    });
  }

  favourites(type:string, body?:any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    let userId = sessionStorage?.getItem("userId")
    const favouriteUrl = this.baseUrls + this.breadCrumb?.favourites
    if(type == 'Get') return this.http?.get(favouriteUrl + userId, {headers,})
    else if(type == 'Delete') return this.http?.post(favouriteUrl + 'delete/' + userId, body, {headers,})
    else  return this.http?.post(favouriteUrl + 'add/' + userId, body, {headers,})
  }

  updateUserDetails(obj: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    let userId = sessionStorage?.getItem("userId")
    return this.http?.patch(this.baseUrls + this.breadCrumb?.updateUserDetails + userId, obj, {
      headers,
    });
  }

  getTransfers(input: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http.get(this.baseUrls + this.breadCrumb?.transfers + input, {headers});
  }

  getTransferList(obj: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage['tokenId']}`,
    });
    return this.http?.post(this.baseUrls + this.breadCrumb?.transfersGetList, obj, {
      headers,
    });
  }
}


