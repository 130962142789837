<app-header [logo]="image?.ratelocLogo" [section1]="'Dashboard'" [section2]="'My Bookings'" [section3]="''"></app-header>

<div class="d-flex justify-content-center body-div">
    <form [formGroup]="transferBookingForm" (ngSubmit)="booking()">
    <div class="summary-container"  *ngIf="transferData">
        <div class="col-md-12 d-flex">
            <div class="row">
            <div class="col-md-8 p-0">
                <div class="card">
                    <div class="card-body">
                        <h5><strong>Transfers</strong></h5>

                        <div id="carouselExampleControls" class="carousel slide" data-bs-interval="false">
                           
                            <div class="carousel-inner">
                                <!-- Loop through transferData and display each item as a carousel slide -->
                                <div *ngFor="let transfer of transferData; let i = index" class="carousel-item" [class.active]="i === 0">
                                    <div class="transfer-content col-md-12 d-flex">
                                        <!-- Car Image -->
                                        <div class="car-image col-md-4">
                                            <p>Private Car - {{ transfer.transferType }}</p>
                                            <div class="img-container">
                                                <img [src]="transfer.vehicleSpecification.images" alt="Car Image" class="d-block w-100"/>
                                            </div>
                                        </div>
                                        <!-- Transfer Details -->
                                        <div class="transfer-details col-md-8">
                                            <h3>Transfers Provided By: R T S Travel</h3>
                                            <ul>
                                                <li><i class="fa-solid fa-clock"></i> Estimated Journey Time {{ transfer.vehicleSpecification.transferDetailInfo[0].name.split('.')[0] }}</li>
                                                <li><i class="fa-solid fa-user"></i> Number Of Minimum Passengers {{ transfer.vehicleSpecification.transferDetailInfo[1].name.split(' ')[0] }}</li>
                                                <li><i class="fa-solid fa-user"></i> Number Of Maximum Passengers {{ transfer.vehicleSpecification.transferDetailInfo[2].name.split(' ')[0] }}</li>
                                                <li><i class="fa-solid fa-suitcase"></i> {{ transfer.vehicleSpecification.transferDetailInfo[3].name }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <!-- Carousel Indicators -->
                            <div *ngIf="transferBody.roundTripDateTime" class="carousel-indicators">
                                <!-- Loop to generate indicators based on transferData length -->
                                <button type="button" *ngFor="let transfer of transferData; let i = index"
                                        [class.active]="i === 0" data-bs-target="#carouselExampleControls" [attr.data-bs-slide-to]="i"
                                        [attr.aria-current]="i === 0 ? 'true' : 'false'" [attr.aria-label]="'Slide ' + (i + 1)"></button>
                            </div>
                        
                            <!-- Custom Previous Button -->
                            <button *ngIf="transferBody.roundTripDateTime" class="carousel-control-prev custom-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <i class="fa-solid fa-chevron-left"></i>
                                <span class="visually-hidden">Previous</span>
                            </button>
                        
                            <!-- Custom Next Button -->
                            <button *ngIf="transferBody.roundTripDateTime" class="carousel-control-next custom-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <i class="fa-solid fa-chevron-right"></i>
                                <span class="visually-hidden">Next</span>
                            </button>
                        
                        </div>
                        
                          <!-- Pickup / Dropoff Details Button -->
                          <div class="pickup-details-btn">
                            <button>Pickup / Drop off Details</button>
                          </div>
                        
                          <!-- Pickup and Dropoff Information -->
                          <div class="pickup-dropoff-info" *ngFor="let transfer of transferData; index as i">
                            <div class="pickup" *ngIf="i==0">
                              <div class="inner-data">
                                  <h4>Out Pickup</h4>
                              <p *ngIf="transferBody.from.type == 'IATA'"><strong>&#x2022; Name: {{ transferBody.from.name }} ({{ transferBody.from.code }}) </strong></p>
                              <p *ngIf="transferBody.from.type == 'ATLAS'"><strong>&#x2022; Name: {{ transferBody.from.name }}</strong></p>
                              <p><strong>&#x2022; Date/Time: {{ getFormattedDate(transferBody.onewayDateTime) }} </strong></p>
                              </div>
                              
                              <div class="inner-data">
                                  <h4>Out Drop off</h4>
                                  <p *ngIf="transferBody.to.type == 'IATA'"><strong>&#x2022; Name: {{ transferBody.to.name }} ({{ transferBody.to.code }}) </strong></p>
                                  <p *ngIf="transferBody.to.type == 'ATLAS'"><strong>&#x2022; Name: {{ transferBody.to.name }}</strong></p>
                              <p><strong>&#x2022; Date/Time: {{ getEstimatedDropoffTime(transferBody.onewayDateTime, transfer.vehicleSpecification.transferDetailInfo[0].name.split('.')[0]) }}</strong></p>
                              </div>
                            </div>
                        
                            <div class="dropoff"  *ngIf="transferBody.roundTripDateTime && i==0">
                              <div class="inner-data">
                                  <h4>Return Pickup</h4>
                                  <p *ngIf="transferBody.to.type == 'IATA'"><strong>&#x2022; Name: {{ transferBody.to.name }} ({{ transferBody.to.code }}) </strong></p>
                                  <p *ngIf="transferBody.to.type == 'ATLAS'"><strong>&#x2022; Name: {{ transferBody.to.name }}</strong></p>
                              <p><strong>&#x2022; Date/Time: {{ getFormattedDate(transferBody.roundTripDateTime)}} </strong></p>
                              </div>
                        
                              <div class="inner-data">
                                  <h4>Return Drop off</h4>
                                  <p *ngIf="transferBody.from.type == 'IATA'"><strong>&#x2022; Name: {{ transferBody.from.name }} ({{ transferBody.from.code }}) </strong></p>
                                  <p *ngIf="transferBody.from.type == 'ATLAS'"><strong>&#x2022; Name: {{ transferBody.from.name }}</strong></p>
                              <p><strong>&#x2022; Date/Time: {{ getEstimatedDropoffTime(transferBody.roundTripDateTime, transfer.vehicleSpecification.transferDetailInfo[0].name.split('.')[0]) }} </strong></p>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
                

                  <!-- <div class="card mt-2">
                    <div class="card-body">
                        <h5><strong>Optional Extras</strong></h5>
                        <ul class="extra-data">
                            <li *ngFor="let extra of extras">
                              {{ extra.name }} at £{{ extra.price }}
                              <div class="quantity">
                                <button (click)="decrementQuantity(extra)"><i class="fa-solid fa-minus"></i></button>
                                <span class="number-pass">{{ extra.quantity }}</span>
                                <button (click)="incrementQuantity(extra)"><i class="fa-solid fa-plus"></i></button>
                              </div>
                            </li>
                          </ul>
                    </div>
                  </div> -->

                    <div class="card mt-2">
                    <div class="card-body text-capitalize">
                        <h5 class="h5 mb-3"> <strong>Additional Information</strong></h5>
                        <!-- <h6 class="h6 fw-bold">room 1 : {{bookingAccommodation?.roomName}}</h6> -->
                        <ng-container >
                            <div class="">
                                <div class="">


                                    <div >
                                        <ng-container>
                                            <div>
                                               <div class="mb-3">
                                                <p><span>Transfer:</span> From {{ transferBody.from.name }} to {{ transferBody.to.name }}</p>
                                                <div class="d-flex">
                                               
                                                    <div class="col-md-3 mx-2">
                                                        <label for="fname" class="form-label">Flight Code <sup
                                                                class="text-danger">*</sup></label>
                                                        <input type="text" class="form-control placeholder-font" maxlength="6"
                                                            id="" formControlName="fromFlightCode" placeholder="Enter Flight Number">
                                                    </div>

                                                    <div class="col-md-3 mx-2">
                                                        <label for="flname" class="form-label">Flight Name <sup
                                                                class="text-danger">*</sup></label>
                                                        <input type="text" class="form-control placeholder-font"
                                                            id="" formControlName="fromFlightName" placeholder="Enter Flight Name">
                                                    </div>
                                                    
                                                    <div class="col-md-3 mx-2">
                                                        <label for="lname" class="form-label">Flight arrival time<sup
                                                                class="text-danger">*</sup></label>
                                                        <input [ngxTimepicker]="picker" type="text" class="form-control placeholder-font"
                                                            id="" formControlName="fromFlightArrival"
                                                            placeholder="Arrival Time" readonly>
                                                            <ngx-material-timepicker #picker></ngx-material-timepicker>
                                                    </div>

                                                </div>
                                               </div>

                                                <div class="mb-3"  *ngIf="transferBody.roundTripDateTime">
                                                    <p><span>Transfer:</span> From {{ transferBody.to.name }} to {{ transferBody.from.name }}</p>
                                                    <div class="d-flex">
                                               
                                                        <div class="col-md-3 mx-2">
                                                            <label for="fname" class="form-label">Flight Code <sup
                                                                    class="text-danger">*</sup></label>
                                                            <input type="text" class="form-control placeholder-font" maxlength="6"
                                                                id="" formControlName="toFlightCode" placeholder="Enter Flight Number">
                                                        </div>

                                                        <div class="col-md-3 mx-2">
                                                            <label for="fname" class="form-label">Flight Name <sup
                                                                    class="text-danger">*</sup></label>
                                                            <input type="text" class="form-control placeholder-font"
                                                                id="" formControlName="toFlightName" placeholder="Enter Flight Name">
                                                        </div>
                                                        
                                                        <div class="col-md-3 mx-2">
                                                            <label for="lname" class="form-label">Flight arrival time<sup
                                                                    class="text-danger">*</sup></label>
                                                            <input [ngxTimepicker]="picker1" type="text" class="form-control placeholder-font"
                                                                id="" formControlName="toFlightDeparture"
                                                                placeholder="Arrival Time" readonly>
                                                                <ngx-material-timepicker #picker1></ngx-material-timepicker>
                                                        </div>
    
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                    </div>

                    <div class="card mt-2">
                    <div class="card-body text-capitalize">
                        <h5 class="h5 mb-3"> <strong>information about guest</strong></h5>
                        <!-- <h6 class="h6 fw-bold">room 1 : {{bookingAccommodation?.roomName}}</h6> -->
                        <ng-container >
                            <div class="">
                                <div class="">


                                    <div >
                                        <ng-container>
                                            <div class="mt-3">
                                                <div class="d-flex mb-3">
                                                    <div class="col-md-2 mx-2">
                                                        <label for="inputState" class="form-label">Title<sup
                                                                class="text-danger">*</sup></label>
                                                        <select id="inputState" class="form-select inputState "
                                                            formControlName="title">
                                                            <option value="Mr" selected>Mr.</option>
                                                            <option value="Miss">Miss</option>
                                                            <option value="Mrs">Mrs</option>
                                                        </select>

                                                    </div>
                                               
                                                    <div class="col-md-4 mx-2">
                                                        <label for="fname" class="form-label">first name <sup
                                                                class="text-danger">*</sup></label>
                                                        <input type="text" class="form-control placeholder-font"
                                                            id="" formControlName="fname" placeholder="Enter Name">
                                                    </div>


                                                    <div class="col-md-4 mx-2">
                                                        <label for="lname" class="form-label">last name<sup
                                                                class="text-danger">*</sup></label>
                                                        <input type="text" class="form-control placeholder-font"
                                                            id="" formControlName="lname"
                                                            placeholder="Enter Last Name">
                                                    </div>

                                                </div>

                                                <div class="d-flex mb-3">
                                                    <div class="col-md-4 mx-2">
                                                        <label for="email" class="form-label">Email Address <sup
                                                                class="text-danger">*</sup></label>
                                                        <input type="email" class="form-control placeholder-font"
                                                            id="" formControlName="agentEmail" placeholder="Email Address">
                                                    </div>


                                                    <div class="col-md-4 mx-2">
                                                        <label for="number" class="form-label">Phone Number<sup
                                                                class="text-danger">*</sup></label>
                                                        <input type="number" class="form-control placeholder-font"
                                                            id="" formControlName="number"
                                                            placeholder="Enter your number">
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                    </div>

                    <div class="card mt-2">
                        <div class="card-body">
                            <h5 class="h4"> <strong>
                                    Manage Your Booking</strong></h5>
                            <div class="col-md-4">
                                <label for="email" class="form-label text-muted">Email Address <sup
                                        class="text-danger">*</sup></label>
                                <input type="text" class="form-control placeholder-font" placeholder="rateloc@email.com"
                                    formControlName="email">
                            </div>
                          
                            <div class="form-check mt-2">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    formControlName="cancellation">
                                <label class="form-check-label " for="flexCheckDefault" class="form-label text-dark">
                                    I would like to receive offers & promotions from RateLoc. I understand that my details will not be shared with third parties for promotional purposes. </label>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4">
                                    <div class="form group">
                                        <label for="country" class="form-label">country/territory code <sup
                                                class="text-danger">*</sup></label>
                                        <input id="country" class="form-control" formControlName="country" readonly>
                                    </div>
                                    <div
                                        *ngIf="transferBookingForm?.get('country')?.invalid && (transferBookingForm?.get('country')?.dirty && transferBookingForm?.get('country')?.touched)">
                                        <div class="text-danger">
                                            country is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label for="phone number" class="form-label">Phone Number<sup
                                            class="text-danger">*</sup></label>
                                    <input type="text" class="form-control placeholder-font"
                                        placeholder="Enter Phone Number" formControlName="agentPhoneNumber" readonly>
                                </div>



                            </div>
                            <div class="col-md-8 mt-3">
                                <label for="specialR" class="form-label">special request</label>
                                <textarea name="" id="" cols="60" rows="3" class="form-control"
                                    formControlName="specialRequest"></textarea>
                            </div>
                        </div>
                    </div>

            </div>

            <div class="col-md-4 scrollable">
                <div class="card">
                    <div class="card-body">
                        <h5 class="h5 fw-bold">Booking Summary</h5>
                        <p><i class="fa-solid fa-car-side"></i> R T S Travel, Private Car</p>
                        <p>
                            {{ getFormattedDate(transferBody.onewayDateTime) }}
                            <span *ngIf="transferBody.roundTripDateTime"> - {{ getFormattedDate(transferBody.roundTripDateTime) }}</span>
                        </p>
                        
                        <!-- Pickup/Dropoff information -->
                        <p>
                            <strong>Out Pickup/Out Dropoff: </strong>
                            {{ transferBody.from.name }}
                            <span *ngIf="transferBody.from.type == 'IATA'"> ({{ transferBody.from.code }})</span>,
                            {{ transferBody.to.name }}
                            <span *ngIf="transferBody.to.type == 'IATA'"> ({{ transferBody.to.code }})</span>
                        </p>
                        
                        <p *ngIf="transferBody.roundTripDateTime">
                            <strong>Pickup/Return Dropoff: </strong>
                            {{ transferBody.to.name }} {{ transferBody.from.name }}
                            <span *ngIf="transferBody.from.type == 'IATA'"> ({{ transferBody.from.code }})</span>
                            <span *ngIf="transferBody.to.type == 'IATA'"> ({{ transferBody.to.code }})</span>
                        </p>
                        
                        <div>
                            <p>
                                <strong>Cancellation fee -</strong> 
                                <span class="text-danger"> Free Cancellation is applicable until {{ transferData[0].deadline }}</span>
                            </p>
                        </div>
                    </div>

                    <div class="border-bottom border-top">
                        <h3 class="m-3">Transfers</h3>
                    </div>

                    <div class="card-body">

                        <div *ngIf="transferData.length === 1">
                            <div class="d-flex justify-content-between mb-2 p-0 dateFont">
                                <p class="text-muted text-sm"><strong>Total:</strong></p>
                                <p class="orange-color"><strong>{{ transferData[0]?.price.amount | currency: 'GBP' }}</strong></p>
                            </div>
                        </div>
                    
                        <div *ngIf="transferData.length === 2">
                            <div class="d-flex justify-content-between mb-2 p-0 dateFont">
                                <p class="text-muted text-sm"><strong>Pick Up</strong></p>
                                <p class=""><strong>{{ transferData[0]?.price.amount | currency: 'GBP' }}</strong></p>
                            </div>
                            <div class="d-flex justify-content-between mb-2 p-0 dateFont">
                                <p class="text-muted text-sm"><strong>Return</strong></p>
                                <p class=""><strong>{{ transferData[1]?.price.amount | currency: 'GBP' }}</strong></p>
                            </div>
                            <div class="d-flex justify-content-between mb-2 p-0 dateFont">
                                <p class="text-muted text-sm"><strong>Total:</strong></p>
                                <p class="orange-color"><strong>{{ (transferData[0]?.price.amount + transferData[1]?.price.amount) | currency: 'GBP' }}</strong></p>
                            </div>
                        </div>
                        <hr>

                        <div class="card-body">
                            <h5 class="h5 fw-bold">Payment Method</h5>
                            <div class="border">

                                <div [ngClass]="{'PaymentBox' :paymentMethod=='manual', 'disabled-box': !bookingData[0].deadline}"
                                    class="cursor-pointer p-2">
                                    <div (click)="paymentMethods('manual')"
                                        class="d-flex text-center paymentMethodInput">
                                        <input type="radio" [(ngModel)]="paymentMethod"
                                            [ngModelOptions]="{standalone: true}" value="manual" class="mx-2"
                                            [disabled]="!bookingData[0].deadline">

                                        <h6 class="h6"> <strong>Add to account</strong></h6>

                                        <p class="badge bg-secondary d-block ml-2">GBP</p>
                                    </div>
                                    <p class="ml-7">This payment method is only available for the rates of corresponding
                                        payment conditions</p>
                                </div>

                                <div (click)="paymentMethods('payByCard')"
                                    [ngClass]="{'PaymentBox' : paymentMethod=='payByCard'}"
                                    class="cursor-pointer p-2 paymentMethodInput">
                                    <div class="d-flex">

                                        <input type="radio" [(ngModel)]="paymentMethod"
                                            [ngModelOptions]="{standalone: true}" value="payByCard" class="mx-2"
                                            [checked]="!bookingData[0].deadline">
                                        <h6 class="h6 " class="cursor-pointer"> <strong> Pay By Card </strong></h6>
                                    </div>

                                    <P class="ml-7 mb-0">The booking cost will be withdrawn from your bank card after the
                                        booking
                                        is made</P>
                                    <P class="p-0 ml-7" style="color: rgb(255, 75, 75);">Note - an additional charge up
                                        to
                                        1.5%
                                        will be applied on card payment.</P>
                                    <!-- <div class="btn btn-warning btn-sm mb-3 text-white">
                                        <span class="ps-3">Pay $ {{ calculateTotalPrice() }}</span>
                                        <span class="fa fa-arrow-right"></span>
                                    </div> -->
                                    <!-- <div class="text-center">
                                        <button class="btn mt-3 text-capitalize detailBtn ">Pay $ {{ calculateTotalPrice() }}
                                        </button>
                                    </div> -->
                                    
                                </div>
                                <input class="me-2" type="checkbox" formControlName="cancellation">
                                <span *ngIf="bookingData[0].deadline">I hereby acknowledge and accept the cancellation policy for this booking, as well as the <strong
                                        class="text-primary">terms & conditions </strong></span>
                                <span *ngIf="!bookingData[0].deadline">I hereby acknowledge and accept the <strong
                                        class="text-primary">terms & conditions </strong></span>
                                        <div 
                                        *ngIf="(transferBookingForm.controls['cancellation']?.invalid || !transferBookingForm.controls['cancellation']?.value) && (transferBookingForm.controls['cancellation']?.dirty || transferBookingForm.controls['cancellation']?.touched)">
                                        <p  class="text-danger">{{ !bookingData[0].deadline ? "* Please accept the cancellation policy" : "Please accept the terms and condition"}}</p>
                                    </div>
                            </div>

                            <!-- <div *ngIf="paymentMethod=='payByCard'" class="mt-2">

                                <div class="col-12">
                                    <div class="d-flex flex-column">
                                        <p class="text mb-1">Card Holder Name</p>
                                        <input class="form-control mb-3" type="text" placeholder="Name" value="rateloc">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex flex-column">
                                        <p class="text mb-1">Card Number</p>
                                        <input class="form-control mb-3" type="text" placeholder="1234 5678 435678">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="d-flex flex-column">
                                        <p class="text mb-1">Expiry</p>
                                        <input class="form-control mb-3" type="text" placeholder="MM/YYYY">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="d-flex flex-column">
                                        <p class="text mb-1">CVV/CVC</p>
                                        <input class="form-control mb-3 pt-2 " type="password" placeholder="***">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="btn btn-warning btn-sm mb-3 text-white">
                                        <span class="ps-3">Pay $243</span>
                                        <span class="fas fa-arrow-right"></span>
                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <div class="text-center">
                            <button class="btn mt-3  text-capitalize detailBtn " type="submit">Book Now</button>
                        </div>
                    </div>
                </div>
            </div>
           </div>
            
        </div>
    </div>
</form>
</div>

<div *ngIf="loader" class="loader">
    <app-loader></app-loader>
    <p>Please wait while we confirm your booking</p>
</div>

<!-- <button class="modal-button" (click)="openModal()">click</button> -->

<!-- successfull modal -->


<ng-template #successModal>
    <div class="whole-modal">
        <div class="success-header">
            <div class="d-flex justify-content-between">
                <img class="rateloc-logo" src="/assets/images/logo.svg" alt="">
            </div>
            <p class="ref-no">Booking ref:  <span>{{bookingDetails.data.bookingRefId}}</span></p>
          </div>
          
          <div class="transfer-summary">
           <div class="guest-data">
            <h3>1 X Private transfer (1-3 people)</h3>
            <p>Please print this e-Ticket, and present this to our representative or driver.</p>
           </div>
          
            <div class="details-section">
              <div class="details-info">
                <h4><i class="fa-solid fa-user"></i> Primary Passenger</h4>
                <div>
                    <p class="key">Name</p>
                    <p class="value">{{bookingDetails.data.passengers.leadGuest}}</p>
                </div>
               <div>
                <p class="key">Who is traveling</p>
                <p class="value">{{bookingDetails.data.passengers.pax.adults + bookingDetails.data.passengers.pax.children + bookingDetails.data.passengers.pax.infants}} Passenger</p>
               </div>
              </div>
          
              <div class="details-info">
                <h4><i class="fa-solid fa-car-side"></i> Vehicle</h4>
                <div>
                    <p class="key">Vehicle type</p>
                    <p class="value">1 X {{bookingDetails.data.journey[0].transferType}} ({{bookingDetails.data.journey[0].vehicleRules[1].value}}-{{bookingDetails.data.journey[0].vehicleRules[2].value}} people) <span>(Pick-up)</span></p>
                    <p *ngIf="bookingDetails.data.journey.length > 1" class="value">1 X {{bookingDetails.data.journey[1].transferType}} ({{bookingDetails.data.journey[1].vehicleRules[1].value}}-{{bookingDetails.data.journey[1].vehicleRules[2].value}} people) <span>(Return Pick-up)</span></p>
                </div>
              </div>
            </div>
          
            <div class="journey-section">
                <ng-container *ngFor=" let journey of bookingDetails.data.journey index as i">
                    <div class="journey">
                        <h4><i class="fa-solid fa-location-dot"></i> Journey {{i+1}}</h4>
                      <div class="d-flex">
                        <div class="info-class">
                            <p>From</p>
                            <p>To</p>
                            <p>Date</p>
                            <p>Flight number</p>
                            <!-- <p>Airline name</p> -->
                            <p>Accommodation</p>
                            <p>Luggage Allowance</p>
                        </div>
                        <div class="data">
                            <p>{{journey.from.description}}</p>
                            <p>{{journey.to.description}}</p>
                            <p>{{journey.date}} at {{journey.time}}</p>
                            <p>{{journey.flightInfo.code}}</p>
                            <!-- <p>{{journey.flightInfo.companyName}}</p>  -->
                            <p>{{journey.from.type == 'ATLAS' ? journey.from.description : journey.to.description}}</p> 
                            <p>{{journey.vehicleRules[3].description}}</p> 
                        </div>
                      </div>
                      </div>
                </ng-container>
            </div>
          </div>
    </div>
    
    </ng-template>
    