<!-- header.component.html -->
<nav class="navbar navbar-expand-md navbar-light p-0 bg-white fixed-top"
  [ngClass]="router == '/Listing'?'':'shadow-md'">
  <div class="container-fluid">
    <!-- Logo -->
    <a class="navbar-brand lg:ml-6 p-0" [routerLink]="['/']">
      <img class="h-20 w-100" [src]="logo" alt="RateLocLogo" />
    </a>

    <!-- Hamburger Menu Icon -->
    <button class="navbar-toggler mr-3" type="button" (click)="toggleMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Navigation Links -->
    <div [ngClass]="{'hidden': !isMenuOpen, 'navbar-collapse': true}" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item mx-4 my-4">
          <a [routerLink]="section1 === 'Home' ? '/' : '/' + section1" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="nav-link nav-section text-gray-600 duration-500 font-poppins">
            {{ section1 }}
          </a>
        </li>
        <li class="nav-item mx-4 my-4">
          <a [ngStyle]="{'width': section2 == 'My Bookings' ? '8rem' : ''}"
            routerLink="/{{ section2 == 'My Bookings'?'MyBookings':section2 }}" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="nav-link nav-section text-gray-600 duration-500 font-poppins">{{ section2 == 'About'? 'About Us' :
            section2 }}</a>
        </li>
        <li *ngIf="section3" class="nav-item mx-4 my-4">
          <a routerLink="/{{ section3 }}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
            class="nav-link nav-section text-gray-600 duration-500 font-poppins">{{ section3 == 'Contact'? 'Contact Us'
            : section3 }}</a>
        </li>
        <li class="nav-item mx-4 my-4" *ngIf="router != '/'">
          <mat-slide-toggle class="font-poppins" [disableRipple]="true" [disabled]="router == '/Dashboard'?false:true"
            [hideIcon]="true" (change)="upliftToggle($event.checked)" [(ngModel)]="isUpliftChecked">&nbsp;Uplift
          </mat-slide-toggle>
        </li>
        <li *ngIf="!['/', '/About', '/Contact','/Register']?.includes(router)" class="nav-item mx-2 my-4">
          <a (click)="onChangeRegion(regionPopup)"
            [ngClass]="{'disabled': !stopRecursiveApiCall && router == 'Listing'}"
            class="nav-link nav-section text-gray-600 duration-500 font-poppins currency"><img class=""
              src="/assets/icons/CurrencyIcon.jpg" alt=""><span class="mx-2">|</span><span
              class="d-flex justify-content-end">{{selectedCode}}</span></a>
        </li>


        <!-- ****** Do not change the classes of below tags as we are using it in directives ****** -->
        <div class="container dropDownContainer mx-4 my-4 p-0"
          *ngIf="!['/', '/About', '/Contact','/Register']?.includes(router)">
          <div class="dropdown">
            <div class="dropdown-toggle accounts" (click)="toggleDropdown()">
              <img src="assets/images/person.svg">
              <span class="dropdown-account-toggle-span">Hi {{firstName | titlecase }}</span>
              <!-- <button style="color: #ec6408" type="button" class= "dropdown-toggle accounts" (click)="toggleDropdown()"><img src="assets/images/person.svg"><span class="dropdown-account-toggle-span">{{name}}</span></button>  -->
              <!-- <ul *ngIf="isDropdownVisible" class="menuDropdown"> 
                <li><button class="dropdown-item" routerLink="/myAccount"><img src="/assets/images/my-account-icon.svg" alt=""><span>My Accounts</span></button></li> 
                <li><button class="dropdown-item"><img src="/assets/images/favourite-icon-rateloc.svg" alt=""> Favourites</button></li>
                <li><button class="dropdown-item"> Quantum</button></li>
                <li><button class="dropdown-item" (click)="logOut()" routerLink="/"><i class="fa fa-solid fa-power-off"></i> Log Out</button></li>
              </ul>  -->
              <!-- <div class="dropdown-div"> -->
              <!-- <div class="dropdown-item">
                  <div class="d-flex p-3">
                    <div class="ml-3 lh-1">
                      <h5 class="mb-0">{{name}}</h5>
                      <p class="mb-0 mt-1 text-muted">{{email}}</p>
                    </div>
      
                  </div>
                  
                </div> -->
            </div>
            <ul class="account-menu mb-0" *ngIf="isDropdownVisible">
              <div class="nameDiv">
                <div class="image-box">
                  <span class="name-initial">{{firstName[0] | titlecase }}</span>
                </div>
                <div class="ml-3 lh-1 email-sizing">
                  <h5 class="mb-0">{{firstName | titlecase }} {{lastName | titlecase }}</h5>
                  <p class="mb-0 mt-1 text-muted">{{email}}</p>
                </div>

              </div>
              <hr>
              <li (mouseover)="hover[1] = true" (mouseleave)="hover[1] = false"><a routerLink="/myAccount"><img
                    [src]=" hover[1] ? '/assets/icons/my-account-hover.svg' :'/assets/icons/my-account.svg'" alt="">My
                  Account</a></li>
              <li *ngIf="role == 'ROLE_ADMIN'" (mouseover)="hover[2] = true" (mouseleave)="hover[2] = false"><a
                  class="quantumIcon" href="http://quantrol.io" target="_blank"><img
                    [src]=" hover[2] ? '/assets/icons/quantum-icon-hover.svg' :   '/assets/icons/quantum-icon.svg'"
                    alt="">Quantrol</a></li>
              <li (mouseover)="hover[3] = true" (mouseleave)="hover[3] = false"><a (click)="setUrl()"
                  routerLink="/favourites"><img
                    [src]=" hover[3] ? '/assets/icons/heart-hover.svg' :   '/assets/icons/heart.svg'"
                    alt="">Favourites</a></li>
              <li (mouseover)="hover[4] = true" (mouseleave)="hover[4] = false" class="mb-1 log-out text-muted"><a
                  (click)="logOut()" routerLink="/"><img
                    [src]=" hover[4] ? '/assets/icons/log-out-hover-rateloc.svg' :   '/assets/icons/log-out-rateloc.svg'"
                    alt="">Log Out</a></li>

            </ul>
          </div>
        </div>
        <!-- <div class="dropdown" *ngIf="router == '/Dashboard'"> 
          <button class="btn fw-bold rounded-5 border-5 dropdown-toggle " style="color: black;"  type="button" id="logout-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-bars"></i>
          </button>
          <ul class="dropdown-menu menuDropdown border-0 shadow-sm" style="background-color: whitesmoke; padding-right: 1rem;" aria-labelledby="logout-dropdown">

            <li  class="nav-item mx-4 my-4"><button class="dropdown-item logout nav-link text-gray-600 duration-500 font-poppins" (click)="logOut()" routerLink="/">Log Out</button></li>
            <li><button class="dropdown-item nav-item mx-4 my-4" href="#">My Account</button></li>
          </ul>
        </div> -->

        <!-- <li class="nav-item mx-4 my-4" *ngIf="router == '/Dashboard'">
          <button class="logout nav-link text-gray-600 duration-500 font-poppins" (click)="logOut()">Log Out</button>
        </li> -->

        <!-- <li class="nav-item mx-3 my-0">
          <span class="nav-link text-gray-600 duration-500 call font-poppins">
            <i class="fa fa-phone mx-2 icon" aria-hidden="true"></i>
            +44 331 630 0226
          </span>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
<ng-template #regionPopup>
  <div class="region-popup">
    <div class="close-btn" (click)="dis()">
      <img src="/assets/icons/x-lg.svg" alt="">
    </div>
    <div>
      <h2>Choose your country or currency</h2>
      <p>Products may have different prices and availability based on country/region.</p>
    </div>
    <div class="region-message">
      <h3>Suggested for you</h3>
      <div class="row">
        <div class="button-list col-md-12">
          <div (click)="onChangeCurrency('GBP', 'United Kingdom, UK')" [ngClass]="{'active': selectedCode == 'GBP'}"
            class="col-md-4 region-buttons first-grid-button active">
            <span class="country-column">United Kingdom</span>
            <span class="currency-column">GBP</span>
          </div>

          <div (click)="onChangeCurrency('INR', 'India, IN')" [ngClass]="{'active': selectedCode == 'INR'}"
            class="col-md-4 region-buttons">
            <span class="country-column">India</span>
            <span class="currency-column">INR</span>
          </div>


          <div (click)="onChangeCurrency('AED', 'Dubai, UAE')" [ngClass]="{'active': selectedCode == 'AED'}"
            class="col-md-4 region-buttons">
            <span class="country-column">Dubai</span>
            <span class="currency-column">AED</span>
          </div>

        </div>
      </div>
    </div>

    <div class="other-regions">
      <h4>All Other Region</h4>
      <div class="col-md-12 button-list">
        <div (click)="onChangeCurrency( country?.currency, country?.value)"
          [ngClass]="{'active': selectedCode == country?.currency}" class="col-md-4 region-buttons"
          *ngFor="let country of countries?.countries">
          <span class="country-column">{{country?.display}}</span>
          <span class="currency-column">{{country?.currency}}</span>
        </div>
      </div>
    </div>
  </div>


</ng-template>

<ng-template #currencyNotice>
  <div class="restrict">
    <div class="crossButton">
      <button type="button" class="close " aria-label="Close" (click)="dis()">
        <span aria-hidden="true" class="larger-cross">
          <i class="fa fa-times"></i> <!-- Font Awesome cross icon -->
        </span>
      </button>
    </div>
    <div class="content  d-flex align-items-center justify-content-center font-poppins pt-4 pb-3">
      <div class="center-grid">
        <h5 class="mt-3 text-center">If you want to change currency you have to do it from Homepage</h5>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button (click)="dis()" class="text-danger m-2">Cancel</button>
      <button (click)="navigateDashboard()" class=" m-2 ms-3 main-button">Go To Homepage</button>
    </div>
  </div>

</ng-template>
<ng-template #upliftPopup>
  <div class="restrict">
    <div class="crossButton">
      <button type="button" class="close " aria-label="Close" (click)="upliftDismiss()">
        <span aria-hidden="true" class="larger-cross">
          <i class="fa fa-times"></i> <!-- Font Awesome cross icon -->
        </span>
      </button>
    </div>
    <div class="content d-flex align-items-center justify-content-center font-poppins pt-4 pb-3">
      <div class="center-grid">
        <h5 class="mt-3 text-center">To activate the Uplift, please set the markup between 1 to 20 percent.</h5>
      </div>
    </div>
    <div class="font-poppins input-container">
      <input type="number" [(ngModel)]="upliftValue" (keydown)="preventInvalidInput($event)"
        (input)="validateInput($event)" />
      <span class="percentage-symbol">%</span>
    </div>
    <div class="d-flex justify-content-end">
      <button (click)="upliftDismiss()" class="close-button m-2">Close</button>
      <button (click)="updateUplift()" class=" m-2 update-button" [ngClass]="{'disabled': upliftValue<1}">Update</button>
    </div>
  </div>

</ng-template>